// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceOrderList {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
}
.custom-table-header th {
  font-weight: 500;
  color: rgb(30, 56, 75);
}

.custom-table-row td {
  font-weight: normal;
  color: #6c757d;
}
.custom-table-subClaimDetails {
  border: 2px solid #dee2e6;
  margin: 1.8rem 1rem 1.8rem 2.5rem;
  width: 92%;
}
.custom-table-subClaimDetails thead th {
  background-color: #f5f5f5;
}

.custom-table-subClaimDetails tbody tr {
  border-bottom: none;
  background-color: #f5f5f5;
}

.custom-table-subClaimDetails th,
.custom-table-subClaimDetails td {
  border: none;
}

.custom-table-header {
  border-top: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ServiceOrderList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,iCAAiC;EACjC,UAAU;AACZ;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".ServiceOrderList {\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 0px;\n}\n.custom-table-header th {\n  font-weight: 500;\n  color: rgb(30, 56, 75);\n}\n\n.custom-table-row td {\n  font-weight: normal;\n  color: #6c757d;\n}\n.custom-table-subClaimDetails {\n  border: 2px solid #dee2e6;\n  margin: 1.8rem 1rem 1.8rem 2.5rem;\n  width: 92%;\n}\n.custom-table-subClaimDetails thead th {\n  background-color: #f5f5f5;\n}\n\n.custom-table-subClaimDetails tbody tr {\n  border-bottom: none;\n  background-color: #f5f5f5;\n}\n\n.custom-table-subClaimDetails th,\n.custom-table-subClaimDetails td {\n  border: none;\n}\n\n.custom-table-header {\n  border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
