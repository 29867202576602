import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class ConfirmationDialog extends Component {
  render() {
    const {
      show,
      onHide,
      onConfirm,
      title,
      message,
      confirmText,
      cancelText,
      confirmVariant,
      cancelVariant,
    } = this.props

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button variant={cancelVariant || 'secondary'} onClick={onHide}>
            {cancelText || 'Cancel'}
          </Button>
          <Button variant={confirmVariant || 'danger'} onClick={onConfirm}>
            {confirmText || 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ConfirmationDialog
