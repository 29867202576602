import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import Icon from '../../img/delivery-box (1) 1.svg'
import { cancelBookedAppointment } from '../../api/serviceavenger'

const CancelServiceOrderDialog = ({
  openCancelService,
  handleToggleCancelService,
  cancelReasons,
  servicerData,
  serviceOrderListObject,
  uiservicerData,
  getServicerOrderParent,
}) => {
  const [cancelReasonCode, setCancelReasonCode] = useState({ cancelCode: '', cancelReason: '' })

  const handleCancelOrder = async () => {
    if (!cancelReasonCode) {
      return toast.error('Please Select Cancel Reason')
    }

    handleToggleCancelService()

    const reqBody = {
      serviceAvengerContractNumber: serviceOrderListObject?.contract.contractNumber || '',
      serviceOrderNumber: serviceOrderListObject?.serviceOrderId || '',
      crmKey: serviceOrderListObject?.serviceOrder?.crmKey || '',
      firstName: serviceOrderListObject?.consumer.firstName || '',
      lastName: serviceOrderListObject?.consumer.lastName || '',
      phone: serviceOrderListObject?.consumer.phone || '',
      productSequenceNumber: serviceOrderListObject?.product?.productSequenceNumber || '',
      servicerID: serviceOrderListObject?.serviceOrder?.servicerID || '',
      servicerAccountId: serviceOrderListObject?.serviceOrder?.servicerAccountId || '',
      appointmentDate: serviceOrderListObject?.serviceOrder?.appointmentDate
        ? new Date(serviceOrderListObject?.serviceOrder?.appointmentDate)
            ?.toISOString()
            ?.split('T')[0]
        : '',
      appointmentTimeSlot: serviceOrderListObject?.serviceOrder?.appointmentTimeSlot || '',
      servicerPhone: uiservicerData?.dispatchMethod.primaryPhone || '',
      servicerEmail: uiservicerData?.dispatchMethod.primaryEmail || '',
      cancelReason: cancelReasonCode?.cancelReason || '',
      cancelCode: cancelReasonCode?.cancelCode || '',
      subStatus: cancelReasonCode?.cancelCode || '',
      providerType: serviceOrderListObject?.serviceOrder?.providerType || '',
      servicerName: servicerData?.servicerName || uiservicerData?.account?.businessName || '',
    }
    const toastId = toast.loading('Cancelling Service Order', { duration: 0 })
    try {
      const response = await cancelBookedAppointment(reqBody)
      toast.dismiss(toastId)

      if (response?.data?.isSuccess) {
        getServicerOrderParent()
        toast.success('Cancelled Successfully')
      }
    } catch (error) {
      toast.dismiss(toastId)
      toast.error(error?.message || 'Something Went Wrong')
    }
  }

  const handleAddcancelReason = (event) => {
    const selectedStatusName = event.target.value

    const selectedStatus = cancelReasons?.find((status) => selectedStatusName === status.reasonName)
    setCancelReasonCode({ cancelCode: selectedStatus.reasonCode, cancelReason: selectedStatusName })
  }

  return (
    <Dialog
      open={openCancelService}
      aria-labelledby='customized-dialog-title'
      PaperProps={{
        style: {
          borderRadius: '20px',
          width: '380px',
        },
      }}
    >
      <DialogTitle className='text-center d-flex align-items-center justify-content-center pt-4'>
        <div
          className='bg-light rounded-circle d-flex align-items-center justify-content-center'
          style={{ width: '80px', height: '80px' }}
        >
          <div className='d-flex align-items-center justify-content-center'>
            <img
              src={Icon}
              alt='user-pic'
              height='45px'
              width='45px'
              style={{
                background: 'rgba(235, 248, 255, 1)',
                padding: '10px',
                borderRadius: '50%',
              }}
            />
          </div>
        </div>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleToggleCancelService}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogActions className='d-flex justify-content-center flex-column align-items-center p-3'>
        <Grid className='text-center'>
          <p className='text-muted text-center mt-2' style={{ fontSize: '14px' }}>
            Do you want to Cancel the Service Order ID?
          </p>
        </Grid>
        <p className='text-muted text-center mt-2' style={{ fontSize: '12px' }}>
          This action will cancel all the scheduled appointments for this service order ID.
        </p>
        <p className='text-muted text-center mt-2' style={{ fontSize: '12px' }}>
          Please provide the reason to cancel :
        </p>
        <Grid className='mt-3'>
          <FormControl size='small' sx={{ width: '300px' }}>
            <InputLabel id='demo-select-small-label' className='small'>
              Cancel
            </InputLabel>
            <Select
              fullWidth
              labelId='demo-select-small-label'
              value={cancelReasonCode.cancelReason}
              label='Cancel'
              onChange={(event) => handleAddcancelReason(event)}
              className='small'
            >
              {Array.isArray(cancelReasons) &&
                cancelReasons?.map((item) => (
                  <MenuItem key={item.reasonCode} value={item?.reasonName} className='small'>
                    {item?.reasonName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid className='mt-4'>
          <Button
            className='text-capitalize'
            size='large'
            variant='outlined'
            color='primary'
            onClick={handleToggleCancelService}
          >
            No
          </Button>
          <Button
            className='text-capitalize ms-4'
            disabled={cancelReasonCode.cancelReason === '' ? true : false}
            size='large'
            variant='contained'
            color='primary'
            onClick={() => handleCancelOrder()}
          >
            Yes
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CancelServiceOrderDialog
