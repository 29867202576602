import React, { Component } from 'react'
import moment from 'moment'
import { Accordion, Card, Col, Row } from 'react-bootstrap'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForceAppointment from './ForceAppointment'

class ForceAppointmentDay extends Component {
  constructor(props) {
    super(props)
    this.groupedAppointment = []
  }
  state = {
    appointments: [],
  }

  componentDidMount() {
    const { date } = this.props
    const { appointmentList, data } = date
    this.setState({
      appointments: appointmentList || data || [],
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      const { appointmentList, data } = this.props.date
      this.setState({
        appointments: appointmentList || data || [],
      })
    }
  }

  getWeekDay() {
    let dayOfWeek = moment(this.props.date.date).day()
    switch (dayOfWeek) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
      default:
        return ''
    }
  }

  displayAppointments(appointments) {
    const {
      bookedAppointment,
      date,
      handleAppointmentSelect,
      selectedAppointment,
      showPopupAppointmentDetail,
    } = this.props

    this.groupedAppointment = []
    for (const item of appointments) {
      let index = this.groupedAppointment.findIndex(
        (x) => x.serviceProvider === item.serviceProvider,
      )
      if (index >= 0) {
        this.groupedAppointment[index].appointments.push(item)
      } else {
        this.groupedAppointment.push({
          type: 'soft',
          svcrName: item.serviceProvider,
          appointments: item.timeBand, // This isn't using the timeband sort from above
          servicerId: item.servicerId,
          dispatchMethodEmail: item.dispatchMethodEmail || '',
          dispatchMethodPhone: item.dispatchMethodPhone || '',
          dispatchAddress: item.dispatchAddress || '',
          notes: item.notes || '',
          servicerAccount: item.servicerAccount || '',
        })
      }
    }

    return (
      this.groupedAppointment &&
      this.groupedAppointment.map((appoint, i) => {
        return (
          <ForceAppointment
            appointment={appoint}
            handleAppointmentSelect={handleAppointmentSelect}
            selectedAppointment={selectedAppointment}
            bookedAppointment={bookedAppointment}
            type={appoint.type}
            servicer={appoint.svcrName}
            servicerId={appoint.servicerId}
            appointments={appoint.appointments}
            index={i}
            showPopupAppointmentDetail={showPopupAppointmentDetail}
            date={date.date}
          />
        )
      })
    )
  }

  render() {
    const { appointments } = this.state
    const { date, handleNextWeek, handleLastWeek, index, total, showLast } = this.props

    return (
      <Col style={{ 'padding-left': '0', 'padding-right': '0' }}>
        <Card
          className='text-center mb-2'
          text='white'
          style={{ border: 'none', 'background-color': 'white !important' }}
        >
          <Card.Header
            style={{
              'font-size': '.7rem',
              'background-color': '#f2f4ff',
              border: 'none',
              color: 'black',
            }}
          >
            <Row>
              {showLast && index === 0 && (
                <Col style={{ 'padding-left': '0', 'padding-right': '0' }} xs={2}>
                  <FontAwesomeIcon
                    style={{ 'margin-top': '15px', width: '100%', cursor: 'pointer' }}
                    size='1x'
                    icon={faAngleLeft}
                    onClick={() => handleLastWeek()}
                  />
                </Col>
              )}
              <Col>
                {this.getWeekDay()}
                <br />
                {date.date}
              </Col>
              {index === total - 1 && (
                <Col style={{ 'padding-left': '0', 'padding-right': '0' }} xs={2}>
                  <FontAwesomeIcon
                    style={{ 'margin-top': '15px', width: '100%', cursor: 'pointer' }}
                    size='1x'
                    icon={faAngleRight}
                    onClick={() => handleNextWeek()}
                  />
                </Col>
              )}
            </Row>
          </Card.Header>
          <Accordion
            style={{
              'font-size': '0.6rem',
              'margin-top': '10px',
              height: '300px',
              'background-color': 'white',
              'border-right': '0.01rem dashed gray',
              'overflow-x': 'hidden',
              'overflow-y': 'auto',
            }}
          >
            {this.displayAppointments(appointments)}
          </Accordion>
        </Card>
      </Col>
    )
  }
}

export default ForceAppointmentDay
