// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductList {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
}

.productListItem {
  cursor: pointer;
}

.purchaseDate{
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".ProductList {\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 0px;\n}\n\n.productListItem {\n  cursor: pointer;\n}\n\n.purchaseDate{\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
