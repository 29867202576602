// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-header {
  width: 120px;
  margin: 0;
  padding: 0;
}

.accordion-button {
  width: 110px;
  margin: 0;
  padding: 0;
}

.accordion-button::after {
  transform: none !important;
  transition: none !important;
}

.accordion-button:after {
  content: none;
}

.accordion-button:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  color: black;  
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: black;  
  background-color: transparent;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;AAClB","sourcesContent":[".accordion-header {\n  width: 120px;\n  margin: 0;\n  padding: 0;\n}\n\n.accordion-button {\n  width: 110px;\n  margin: 0;\n  padding: 0;\n}\n\n.accordion-button::after {\n  transform: none !important;\n  transition: none !important;\n}\n\n.accordion-button:after {\n  content: none;\n}\n\n.accordion-button:focus {\n  outline-style: none;\n  box-shadow: none;\n  border-color: transparent;\n  color: black;  \n  background-color: transparent;\n  box-shadow: none;\n}\n\n.accordion-button:not(.collapsed) {\n  color: black;  \n  background-color: transparent;\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
