import * as React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import Icon from '../../img/delete 1FInalConfirmation.svg'

export default function FinalConfirmationDialog({
  failureId,
  consumerDetails,
  bookingContent,
  finalConfirmDialog,
  setFinalConfirmDialog,
  appointmentDay,
  appointmentDate,
  finalBookingTimeSlot,
  closeReassignDialogParent,
  getServicerOrderParent,
}) {
  const handleCloseAll = async () => {
    closeReassignDialogParent()
    getServicerOrderParent()
    setFinalConfirmDialog(false)
  }

  const handleClose = () => {
    setFinalConfirmDialog(false)
  }

  const changeDateFormat = () => {
    const [year, month, day] = appointmentDate.split('-')
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const fullMonthName = monthNames[parseInt(month, 10) - 1]
    const formattedDate = `${fullMonthName} ${parseInt(day, 10)}, ${year}`
    return formattedDate
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={finalConfirmDialog}
      PaperProps={{
        style: {
          borderRadius: '20px',
          width: '29rem',
          height: '33rem',
        },
      }}
    >
      <DialogTitle className='text-center' sx={{ m: 2, p: 2, mb: 0 }} id='customized-dialog-title'>
        <img
          src={Icon}
          alt='user-pic'
          height='45px'
          width='45px'
          className='rounded-circle p-2'
          style={{ background: 'rgba(235, 248, 255, 1)' }}
        />
      </DialogTitle>

      <DialogContent className='text-center'>
        <Grid>
          <span className='fw-semibold' style={{ fontSize: '15px' }}>
            {bookingContent?.serviceProvider} has been re-assigned to Service Order #{failureId}
            &nbsp; for {appointmentDay}, {changeDateFormat(appointmentDate)}&nbsp;
            {finalBookingTimeSlot}.
          </span>
        </Grid>

        <Grid className='mt-4'>
          <span className='fs-6'>
            Reassigned notification has been sent to Service Provider &nbsp;{' '}
            <span className='fw-semibold'>{bookingContent?.serviceProvider} &nbsp;</span>
            at &nbsp;
            <span className='fw-semibold'>
              {bookingContent?.dispatchMethodPhone && (
                <React.Fragment>
                  <br />
                  {bookingContent?.dispatchMethodPhone}
                </React.Fragment>
              )}
              {bookingContent?.dispatchMethodEmail && (
                <React.Fragment>
                  <br />
                  {bookingContent?.dispatchMethodEmail}
                </React.Fragment>
              )}
            </span>
          </span>
        </Grid>

        <Grid className='mt-4'>
          <span className='fs-6'>
            Reassigned notification has been sent to Consumer &nbsp;
            <span className='fw-semibold'>{consumerDetails?.customerName} &nbsp;</span>
            at &nbsp;
            <span className='fw-semibold'>
              {consumerDetails?.phone && (
                <React.Fragment>
                  <br />
                  {consumerDetails?.phone}
                </React.Fragment>
              )}
              {consumerDetails?.email && (
                <React.Fragment>
                  <br />
                  {consumerDetails?.email}
                </React.Fragment>
              )}
            </span>
          </span>
        </Grid>

        <br />
        <Grid item xs={12} className='d-flex justify-content-center mt-4'>
          <Button variant='contained' onClick={handleCloseAll}>
            Okay
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
