import React, { Component } from 'react'
import { Button, Card, Col, Form, ListGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './ProductList.css'

class ProductList extends Component {
  state = {
    showModal: false,
    isBrandValidated: false,
    isBrandValid: null,
    isModelValidated: false,
    isModelValid: null,
    isSerialValidated: false,
    isSerialValid: null,
    isSubcategoryValidated: false,
    isSubcategoryValid: null,
  }

  componentDidMount() {
    this.updateProductSubCategories(this.props.productList)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productList !== this.props.productList) {
      this.updateProductSubCategories(this.props.productList)
    }
  }

  updateProductSubCategories = (productList) => {
    productList.forEach((product) => {
      const { subCategory, modelNumber } = product
      this.props.getProductSubCategory(subCategory, modelNumber, product)
    })
  }

  handleShow = () => {
    this.setState({ showModal: true })
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }

  handleAddProduct = async () => {
    if (this.ValidateModal()) {
      await this.props.handleAddProduct()
      this.handleClose()
      this.resetValidation()
    }
  }

  getVariant = (product) => {
    const { selectedProduct } = this.props
    if (
      selectedProduct?.productSequenceNumber === product?.productSequenceNumber &&
      selectedProduct?.serial === product?.serial &&
      selectedProduct != null
    ) {
      return 'secondary'
    } else {
      return this.product === null ? 'light' : null
    }
  }

  getClass = (product) => {
    const { selectedProduct } = this.props
    return selectedProduct?.productSequenceNumber === product?.productSequenceNumber &&
      selectedProduct?.serial === product?.serial &&
      selectedProduct != null
      ? 'productListItem'
      : 'productListItem text-info'
  }

  renderProducts = () => {
    const { handleProductSelect, isExpired, productList } = this.props

    const sortedList = productList.sort((a, b) => a.productSequenceNumber - b.productSequenceNumber)

    if (sortedList.length > 0) {
      return sortedList.map((product) => {
        const { modelNumber, productSequenceNumber, serial, subCategory } = product

        return (
          <ListGroup.Item
            key={`${productSequenceNumber}${serial}`}
            variant={this.getVariant(product)}
            onClick={isExpired ? null : () => handleProductSelect(product)}
            className={this.getClass(product)}
          >
            {`SEQ#${productSequenceNumber} - ${subCategory} ${modelNumber}`}
          </ListGroup.Item>
        )
      })
    } else {
      return <ListGroup.Item>No products exist.</ListGroup.Item>
    }
  }

  ValidateModal = () => {
    this.setState({
      isBrandValid: this.newBrandInput.value.trim().length === 0 ? false : true,
      isBrandValidated: true,
      isModelValid: this.newModelInput.value.trim().length === 0 ? false : true,
      isModelValidated: true,
      isSerialValid: this.newSerialInput.value.trim().length === 0 ? false : true,
      isSerialValidated: true,
      isSubcategoryValid: this.newSubcategoryInput.value.trim().length === 0 ? false : true,
      isSubcategoryValidated: true,
    })

    return (
      this.newBrandInput.value.trim().length > 0 &&
      this.newModelInput.value.trim().length > 0 &&
      this.newSerialInput.value.trim().length > 0 &&
      this.newSubcategoryInput.value.trim().length > 0
    )
  }

  renderAddProductButton = () => {
    const { coverageProductTypeCodeStr, isExpired, loading, productList } = this.props
    const buttonJSX = (
      <div className='d-flex justify-content-end'>
        <Button
          className='RoundedButton'
          variant='outline-info'
          onClick={() => this.handleShow()}
          disabled={loading}
          size='md'
        >
          <FontAwesomeIcon icon={faPlus} /> Add a new product
        </Button>
      </div>
    )
    if (coverageProductTypeCodeStr === 'RSC') {
      if (productList.length === 0 && !isExpired) {
        return buttonJSX
      }
    } else {
      if (!isExpired) {
        return buttonJSX
      }
    }
  }

  getSubcategoryDisabled = () => {
    return this.props.selectedLossCode ? true : false
  }

  getSubcategoryValue = () => {
    const { newSubcategory, subCategory, selectedLossCode } = this.props
    return selectedLossCode ? subCategory?.subCatCode : newSubcategory
  }

  resetValidation = () => {
    this.setState({
      isBrandValidated: false,
      isModelValidated: false,
      isSerialValidated: false,
      isSubcategoryValidated: false,
    })
  }

  render() {
    const {
      isBrandValid,
      isBrandValidated,
      isModelValid,
      isModelValidated,
      isSubcategoryValid,
      isSubcategoryValidated,
      isSerialValid,
      isSerialValidated,
      showModal,
    } = this.state

    const {
      condition,
      handleInputChange,
      loading,
      manufacturers,
      newModel,
      newSerial,
      subcategories,
    } = this.props
    const conditions = [
      { key: 'New', value: 'N' },
      { key: 'Pre-Owned', value: 'U' },
      { key: 'Refurbished', value: 'R' },
      { key: 'Other', value: 'O' },
    ]

    return (
      <React.Fragment>
        {loading && (
          <Row>
            <Col>
              <Spinner animation='border'></Spinner>
              <br />
              <span className='spinner-text'>Re-loading product list...</span>
            </Col>
          </Row>
        )}
        {!loading && (
          <React.Fragment>
            <Card
              style={{
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                marginTop: '10px',
              }}
            >
              <Card.Body className='ProductList'>
                <Row className='ps-3'>
                  <Col xs={7} className='ps-0'>
                    <h4>Products</h4>
                  </Col>
                  <Col xs={5} className='float-right' style={{ marginBottom: '0.5rem' }}>
                    {this.renderAddProductButton()}
                  </Col>
                </Row>
                <ListGroup variant='flush'>{this.renderProducts()}</ListGroup>
              </Card.Body>
            </Card>
            <Modal show={showModal} keyboard='false'>
              <Modal.Header>
                <Modal.Title>Add New Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row} controlId='newBrand'>
                  <Form.Label column xs={6}>
                    Manufacturer:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      ref={(element) => (this.newBrandInput = element)}
                      as='select'
                      size='sm'
                      onChange={(event) => {
                        handleInputChange(event)
                      }}
                      className={
                        !isBrandValidated ? null : isBrandValid ? 'is-valid' : 'is-invalid'
                      }
                    >
                      <option value=''>Select manufacturer</option>
                      {manufacturers.map((mfg) => (
                        <option key={mfg.brand} value={mfg.brand.toUpperCase()}>
                          {mfg.brand.toUpperCase()}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Please select a manufacturer.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='newModel'>
                  <Form.Label column xs={6}>
                    Model Number:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      className={
                        !isModelValidated ? null : isModelValid ? 'is-valid' : 'is-invalid'
                      }
                      ref={(element) => (this.newModelInput = element)}
                      autoComplete='off'
                      type='text'
                      placeholder='Model number'
                      value={newModel}
                      size='sm'
                      onChange={(event) => {
                        handleInputChange(event)
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide the model number.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='newSubcategory'>
                  <Form.Label column xs={6}>
                    Subcategory:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      className={
                        !isSubcategoryValidated
                          ? null
                          : isSubcategoryValid
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                      ref={(element) => (this.newSubcategoryInput = element)}
                      as='select'
                      size='sm'
                      onChange={(event) => {
                        handleInputChange(event)
                      }}
                      disabled={this.getSubcategoryDisabled()}
                      value={this.getSubcategoryValue()}
                    >
                      <option value=''>Select subcategory</option>
                      {subcategories.map((subcategory) => (
                        <option
                          key={`${subcategory.subCatCode.toUpperCase()}${subcategory.subCatDescription.toUpperCase()}`}
                          value={subcategory.subCatCode.toUpperCase()}
                        >
                          {subcategory.subCatCode.toUpperCase()}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Please select a subcategory.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='newSerial'>
                  <Form.Label column xs={6}>
                    Serial:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      className={
                        !isSerialValidated ? null : isSerialValid ? 'is-valid' : 'is-invalid'
                      }
                      ref={(element) => (this.newSerialInput = element)}
                      autoComplete='off'
                      type='text'
                      placeholder='Serial'
                      value={newSerial}
                      size='sm'
                      onChange={(event) => {
                        handleInputChange(event)
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a serial.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='condition'>
                  <Form.Label column xs={6}>
                    Condition:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      as='select'
                      size='sm'
                      value={condition}
                      onChange={(event) => {
                        handleInputChange(event)
                      }}
                    >
                      {conditions.map((condition) => (
                        <option key={condition.key} value={condition.value}>
                          {condition.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => this.handleClose()}>
                  Close
                </Button>
                <Button className='btn-green' onClick={() => this.handleAddProduct()}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default ProductList
