import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'
import './Loader.css'

class Loader extends Component {
  render() {
    const { loading } = this.props
    if (!loading) return null

    return (
      <div className='loader-container'>
        <div className='loader-overlay'>
          <Spinner animation='border' className='spinner-color' />
          <span className='spinner-text'>Loading...</span>
        </div>
      </div>
    )
  }
}

export default Loader
