import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap'
import ContractCustomer from '../ContractCustomer'
import {
  getCountryList,
  getStateDetail,
  getCountryDetailByCode,
  updateConsumer,
  validateAddress,
} from '../../api/serviceavenger'
import parse from 'html-react-parser'

class UpdateCustomerDetailModal extends Component {
  state = {
    address: '',
    address2: '',
    city: '',
    countryId: '',
    email: '',
    errorMessageContractModal: '',
    firstName: '',
    isAddressValid: null,
    isAddressValidated: false,
    isCityValid: null,
    isCityValidated: false,
    isEmailValid: null,
    isEmailValidated: false,
    isFirstNameValid: null,
    isFirstNameValidated: false,
    isLastNameValid: null,
    isLastNameValidated: false,
    isNewBrandValid: null,
    isNewBrandValidated: false,
    isNewModelValid: null,
    isNewModelValidated: false,
    isNewSerialValid: null,
    isNewSerialValidated: false,
    isNewSubcategoryValid: null,
    isNewSubcategoryValidated: false,
    isPostalCodeValid: null,
    isPostalCodeValidated: false,
    isPurchaseDtValid: null,
    isPurchaseDtValidated: false,
    isStateValid: null,
    isCountryValid: null,
    isStateValidated: false,
    isCountryValidated: false,
    isPrimaryPhoneValid: null,
    isPrimaryPhoneValidated: false,
    isSecondaryPhoneValid: null,
    isSecondaryPhoneValidated: false,
    lastName: '',
    postalCode: '',
    primaryPhone: '',
    secondaryPhone: '',
    state: '',
    models: [],
    country: '',
    countryList: [],
    stateList: [],
    regionCode: '',
    countyCode: '',
    stateCode: '',
    isCountryDisabled: true,
  }

  getCountryList = async () => {
    try {
      const response = await getCountryList()
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ countryList: data?.items || [] })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting Country list:</b><br/>${error.message}`,
      })
    }
  }

  getStateList = async (countryId) => {
    try {
      const response = await getStateDetail(countryId)
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ stateList: data?.items || [] })
        const selectedState = data?.items.find((item) => item?.stateCode === this.props.state)
        this.setState({
          state: selectedState.stateName,
          stateCode: selectedState?.stateCode,
        })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting state list:</b><br/>${error.message}`,
      })
    }
  }

  countryDetailByCode = async (code) => {
    try {
      const response = await getCountryDetailByCode(code)
      const { status, data } = response

      if (status === 200 && data?.items?.length > 0) {
        const selectedCountry = data.items[0]

        if (selectedCountry) {
          this.getStateList(selectedCountry.countryId)
        }
        this.setState({
          country: selectedCountry?.countryName,
          countryId: selectedCountry?.countryId,
          countyCode: selectedCountry?.countryCode,
          regionCode: selectedCountry?.regionCode,
        })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting country detail:</b><br/>${error.message}`,
      })
    }
  }

  onRender = () => {
    this.getCountryList()
    this.countryDetailByCode(this.props.country)
    this.setState({
      ...this.props,
    })
  }

  handleInputChange = async (event) => {
    this.resetValidation()
    if (event != null) {
      const { target } = event
      const { id, value } = target

      switch (id) {
        case 'primaryPhone':
          this.setState({
            [id]: value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          })
          break

        case 'secondaryPhone':
          this.setState({
            [id]: value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          })
          break
        case 'full-address':
          const filterSelectedCountry = this.state.countryList?.find(
            (item) => item?.regionCode === value?.country,
          )
          this.setState(
            {
              city: value.city,
              postalCode: value.postalCode,
              stateCode: value?.state,
              country: filterSelectedCountry?.countryName,
              countryId: filterSelectedCountry?.countryId,
              countyCode: filterSelectedCountry?.countryCode,
              regionCode: filterSelectedCountry?.regionCode,
            },
            async () => {
              if (filterSelectedCountry?.countryId) {
                const stateList = await this.getStateList(filterSelectedCountry?.countryId)
                const filteredState = stateList?.find((item) => item?.stateCode === value?.state)
                if (!!filteredState) {
                  this.setState({
                    state: filteredState?.stateName,
                  })
                }
              }
            },
          )
          break
        // /Need to uncomment the following when country is dynamic
        // case 'country':
        //   const selectedCountry = this.state.countryList?.find(
        //     (item) => item?.countryName === value,
        //   )
        //   if (!!selectedCountry) {
        //     this.getStateList(selectedCountry?.countryId || '')
        //   }

        //   this.setState({
        //     country: value,
        //     countryId: selectedCountry?.countryId,
        //     countyCode: selectedCountry?.countryCode,
        //     regionCode: selectedCountry?.regionCode,
        //   })
        //   break
        case 'state/Province':
          const selectedState = this.state.stateList?.find((item) => item?.stateName === value)
          this.setState({
            state: value,
            stateCode: selectedState?.stateCode,
          })
          break
        default:
          this.setState({ [id]: value })
      }
    }
  }

  handleContractCreateClose = () => {
    this.props.updateShowNewContractModal(false)
  }

  handleContractCreateShow = () => {
    this.resetModal()
  }

  resetValidation = () => {
    this.setState({
      isAddressValid: null,
      isAddressValidated: false,
      isCityValid: null,
      isCityValidated: false,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isFirstNameValidated: false,
      isLastNameValid: null,
      isLastNameValidated: false,
      isPostalCodeValid: null,
      isPostalCodeValidated: false,
      isStateValid: null,
      isCountryValid: null,
      isStateValidated: false,
      isCountryValidated: false,
      isPrimaryPhoneValid: null,
      isPrimaryPhoneValidated: false,
      isSecondaryPhoneValid: null,
      isSecondaryPhoneValidated: false,
    })
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, '')
    return cleanString?.length === 10
  }

  validateModal = async () => {
    const {
      address,
      city,
      email,
      firstName,
      lastName,
      postalCode,
      primaryPhone,
      secondaryPhone,
      state,
      country,
    } = this.state

    let errorMessageContractModal = ''
    const body = {
      address: {
        firstAddressLine: this.state.address,
        city: this.state.city,
        stateCode: this.state.stateCode,
        zipCode: this.state.postalCode,
        country: this.state.regionCode,
      },
    }

    if (!(await this.isValidAddress(body))) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while validating address</b><br/>`,
        isAddressValid: false,
        isAddressValidated: true,
        isCityValid: false,
        isCityValidated: true,
        isPostalCodeValid: false,
        isPostalCodeValidated: true,
        isStateValid: false,
        isStateValidated: true,
      })
    }

    this.setState({
      errorMessageContractModal,
      isAddressValid: address?.length > 0 ? true : false,
      isAddressValidated: true,
      isCityValid: city?.length > 0 ? true : false,
      isCityValidated: true,
      isEmailValid: email?.trim().length === 0 ? false : this.validateEmail(email?.trim()),
      isEmailValidated: true,
      isFirstNameValid: firstName?.length > 0 ? true : false,
      isFirstNameValidated: true,
      isLastNameValid: lastName?.length > 0 ? true : false,
      isLastNameValidated: true,
      isPostalCodeValid: postalCode?.length ? true : false,
      isPostalCodeValidated: true,
      isPrimaryPhoneValid:
        primaryPhone?.trim().length === 0 ? false : this.validatePhone(primaryPhone?.trim()),
      isPrimaryPhoneValidated: true,
      isSecondaryPhoneValid:
        secondaryPhone?.trim().length > 0 ? this.validatePhone(secondaryPhone?.trim()) : null,
      isSecondaryPhoneValidated: secondaryPhone?.trim().length > 0 ? true : false,
      isStateValid: state?.length > 0 ? true : false,
      isCountryValid: country?.length > 0 ? true : false,
      isStateValidated: true,
      isCountryValidated: true,
    })

    return (
      address?.length > 0 &&
      city?.length > 0 &&
      this.validateEmail(email) &&
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      postalCode?.length > 0 &&
      primaryPhone?.replace(/\D/g, '').length === 10 &&
      state?.length > 0 &&
      (secondaryPhone.length === 0 ||
        (secondaryPhone.length > 0 && this.validatePhone(secondaryPhone?.trim())))
    )
  }

  isValidAddress = async (address) => {
    try {
      const response = await validateAddress(address)
      if (response.data.isSuccess) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
  removeExtraStringFromPhoneNumber(phoneNumber) {
    if (phoneNumber.length > 0) {
      return phoneNumber.replace(/[()\s-]/g, '')
    }
    return phoneNumber
  }

  handleClose = () => {
    this.props.onHide()
  }

  onSave = async () => {
    if (await this.validateModal()) {
      try {
        const body = {
          contractInfo: {
            serviceAvengerContractNumber: this.props.contractNumber,
            externalContractNumber: this.props.externalContractNumber,
          },
          consumerInfo: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            address1: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.stateCode,
            zipCode: this.state.postalCode,
            email: this.state.email,
            primaryPhoneNumber: this.removeExtraStringFromPhoneNumber(this.state.primaryPhone),
            secondaryPhoneNumber: this.removeExtraStringFromPhoneNumber(this.state.secondaryPhone),
          },
        }
        const response = await updateConsumer(body)
        const { status, data } = response
        if (status === 200) {
          this.props.handleConsumerUpdate()
        } else {
          this.setState({
            errorMessageContractModal: `<br/><b>Error while updating consumer:</b><br/>${data.message}`,
          })
        }
      } catch (error) {
        this.setState({
          errorMessageContractModal: `<br/><b>Error while updating consumer:</b><br/>${error.message}`,
        })
      }
    }
  }

  render() {
    const {
      address,
      address2,
      city,
      email,
      errorMessageContractModal,
      firstName,
      isAddressValid,
      isAddressValidated,
      isCityValid,
      isCityValidated,
      isCountryDisabled,
      isEmailValid,
      isEmailValidated,
      isFirstNameValid,
      isFirstNameValidated,
      isLastNameValid,
      isLastNameValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isPrimaryPhoneValid,
      isPrimaryPhoneValidated,
      isSecondaryPhoneValid,
      isSecondaryPhoneValidated,
      isStateValid,
      isCountryValid,
      isStateValidated,
      isCountryValidated,
      lastName,
      postalCode,
      primaryPhone,
      secondaryPhone,
      state,
      countryList,
      country,
      stateList,
    } = this.state
    return (
      <Fragment>
        <Row>
          <Col md={12}></Col>
        </Row>
        <Modal onShow={this.onRender} show={this.props.showModal} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Update Consumer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContractCustomer
              address={address}
              address2={address2}
              city={city}
              email={email}
              firstName={firstName}
              handleInputChange={this.handleInputChange}
              isAddressValid={isAddressValid}
              isAddressValidated={isAddressValidated}
              isCityValid={isCityValid}
              isCityValidated={isCityValidated}
              isCountryDisabled={isCountryDisabled}
              isEmailValid={isEmailValid}
              isEmailValidated={isEmailValidated}
              isFirstNameValid={isFirstNameValid}
              isFirstNameValidated={isFirstNameValidated}
              isLastNameValid={isLastNameValid}
              isLastNameValidated={isLastNameValidated}
              isPostalCodeValid={isPostalCodeValid}
              isPostalCodeValidated={isPostalCodeValidated}
              isPrimaryPhoneValid={isPrimaryPhoneValid}
              isPrimaryPhoneValidated={isPrimaryPhoneValidated}
              isSecondaryPhoneValid={isSecondaryPhoneValid}
              isSecondaryPhoneValidated={isSecondaryPhoneValidated}
              isStateValid={isStateValid}
              isCountryValid={isCountryValid}
              isStateValidated={isStateValidated}
              isCountryValidated={isCountryValidated}
              lastName={lastName}
              postalCode={postalCode}
              primaryPhone={primaryPhone}
              secondaryPhone={secondaryPhone}
              stateProvince={state}
              country={country}
              countryList={countryList}
              stateList={stateList}
            ></ContractCustomer>
            {errorMessageContractModal.length > 0 ? (
              <Row className='mt-3 ps-3 pe-3'>
                <Col>
                  <Alert id='errorFound' variant='danger'>
                    {parse(errorMessageContractModal)}
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.handleClose()}>
              Close
            </Button>
            <Button variant='primary' onClick={() => this.onSave()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default UpdateCustomerDetailModal
