// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.availableAppointment,
.selectedAppointment {
  cursor: pointer;
}

.list-group-item {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.modal-xl {
  max-width: 1000px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Appointment.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".availableAppointment,\n.selectedAppointment {\n  cursor: pointer;\n}\n\n.list-group-item {\n  margin-top: 0 !important;\n  margin-bottom: 10px !important;\n}\n\n.modal-xl {\n  max-width: 1000px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
