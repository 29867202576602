import React, { Component, Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { StreetInput } from './StreetInput'

class ContractCustomer extends Component {
  render() {
    const {
      country,
      address,
      address2,
      city,
      email,
      firstName,
      isCountryDisabled,
      handleInputChange,
      isAddressValid,
      isAddressValidated,
      isCityValid,
      isCityValidated,
      isEmailValid,
      isEmailValidated,
      isFirstNameValid,
      isFirstNameValidated,
      isLastNameValid,
      isLastNameValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isPrimaryPhoneValid,
      isPrimaryPhoneValidated,
      isSecondaryPhoneValid,
      isSecondaryPhoneValidated,
      isStateValid,
      isCountryValid,
      isStateValidated,
      isCountryValidated,
      lastName,
      postalCode,
      primaryPhone,
      secondaryPhone,
      stateProvince,
      countryList,
      stateList,
    } = this.props

    return (
      <Fragment>
        <h2>Customer</h2>
        <Form.Group as={Row} controlId='firstName'>
          <Form.Label column xs={6}>
            First Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={
                !isFirstNameValidated ? null : isFirstNameValid ? 'is-valid' : 'is-invalid'
              }
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='First Name'
              size='sm'
              type='text'
              value={firstName}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a first name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='lastName'>
          <Form.Label column xs={6}>
            Last Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={!isLastNameValidated ? null : isLastNameValid ? 'is-valid' : 'is-invalid'}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='Last Name'
              size='sm'
              type='text'
              value={lastName}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a last name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='country'>
          <Form.Label column xs={6}>
            Country:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as='select'
              autoComplete='off'
              disabled={isCountryDisabled || false}
              className={!isCountryValidated ? null : isCountryValid ? 'is-valid' : 'is-invalid'}
              onChange={(event) => {
                handleInputChange(event)
              }}
              size='sm'
              value={country}
            >
              <option key='' value=''>
                Select Country...
              </option>
              {countryList && countryList.length > 0 ? (
                countryList.map((country, i) => (
                  <option key={i} value={country?.countryName}>
                    {country?.countryName}
                  </option>
                ))
              ) : (
                <option key='no-data' value='' disabled>
                  No data found
                </option>
              )}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>Please select a Country.</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='address'>
          <Form.Label column xs={6}>
            Address:
          </Form.Label>
          <Col xs={6}>
            <StreetInput
              address={address}
              handleInputChange={handleInputChange}
              isAddressValid={isAddressValid}
              isAddressValidated={isAddressValidated}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='address2'>
          <Form.Label column xs={6}>
            Address 2:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='Additional Address'
              size='sm'
              type='text'
              value={address2}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='city'>
          <Form.Label column xs={6}>
            City:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={!isCityValidated ? null : isCityValid ? 'is-valid' : 'is-invalid'}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='City'
              size='sm'
              type='text'
              value={city}
            />
            <Form.Control.Feedback type='invalid'>Please provide a city.</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='state/Province'>
          <Form.Label column xs={6}>
            State/Province:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as='select'
              autoComplete='off'
              className={!isStateValidated ? null : isStateValid ? 'is-valid' : 'is-invalid'}
              onChange={(event) => {
                handleInputChange(event)
              }}
              size='sm'
              value={stateProvince || ''}
            >
              <option key='' value=''>
                Select State/Province: ...
              </option>
              {stateList && stateList.length > 0 ? (
                stateList.map((state, i) => (
                  <option key={i} value={state?.stateName}>
                    {state?.stateName}
                  </option>
                ))
              ) : (
                <option key='no-data' value='' disabled>
                  No data found
                </option>
              )}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              Please select a State/Province: .
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='postalCode'>
          <Form.Label column xs={6}>
            Postal Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={
                !isPostalCodeValidated ? null : isPostalCodeValid ? 'is-valid' : 'is-invalid'
              }
              maxLength={10}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='Postal Code'
              size='sm'
              type='text'
              value={postalCode}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid postal code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='email'>
          <Form.Label column xs={6}>
            Email:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={!isEmailValidated ? null : isEmailValid ? 'is-valid' : 'is-invalid'}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='Email'
              size='sm'
              type='email'
              value={email}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='primaryPhone'>
          <Form.Label column xs={6}>
            Primary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={
                !isPrimaryPhoneValidated ? null : isPrimaryPhoneValid ? 'is-valid' : 'is-invalid'
              }
              maxLength={14}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='(123) 456-7890'
              size='sm'
              type='text'
              value={primaryPhone}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a primary phone number.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId='secondaryPhone'>
          <Form.Label column xs={6}>
            Secondary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete='off'
              className={
                !isSecondaryPhoneValidated
                  ? null
                  : isSecondaryPhoneValid
                  ? 'is-valid'
                  : 'is-invalid'
              }
              maxLength={14}
              onChange={(event) => {
                handleInputChange(event)
              }}
              placeholder='(123) 456-7890'
              size='sm'
              type='text'
              value={secondaryPhone}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid secondary phone.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Fragment>
    )
  }
}

export default ContractCustomer
