import React, { Component, Fragment } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'

class DetailModal extends Component {
  toTitleCase = (text) => {
    return text
      .replace(/([A-Z])/g, ' $1') 
      .replace(/^./, (str) => str.toUpperCase())
      .trim()
  }

  renderBodyContent = () => {
    const { bodyContent } = this.props
    if (typeof bodyContent === 'object' && bodyContent !== null) {
      return (
        <div>
          {Object.entries(bodyContent).map(([key, value]) => (
            <div key={key}>
              <strong>{this.toTitleCase(key)}:</strong> {value}
            </div>
          ))}
        </div>
      )
    } else {
      return bodyContent
    }
  }

  render() {
    const { showModal, title, handleClose } = this.props
    return (
      <Fragment>
        <Row>
          <Col md={12}></Col>
        </Row>
        <Modal show={showModal} keyboard={false}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderBodyContent()}</Modal.Body>
          <Modal.Footer className='d-flex justify-content-center'>
            <Button variant='secondary' onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default DetailModal
