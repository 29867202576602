import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import ConfirmationDialog from './Dialogs/ConfirmationDialog/ConfirmationDialog'
class CancelContractModal extends Component {
  state = {
    cancellationDate: '',
    cancellationFee: '',
    dealerRefundAmount: '',
    customerRefundAmount: '',
    hasOverideFlg: false,
    isCancellationDateValid: null,
    isCancellationDateValidated: false,
    iscancellationFeeValid: null,
    iscancellationFeeValidated: false,
    isdealerRefundAmountValid: null,
    isdealerRefundAmountValidated: false,
    iscustomerRefundAmountValid: null,
    iscustomerRefundAmountValidated: false,
    showConfiramationDialog: false,
  }
  validateModal = () => {
    const {
      cancellationDate,
      cancellationFee,
      dealerRefundAmount,
      customerRefundAmount,
      hasOverideFlg,
    } = this.state

    let errorMessageContractModal = ''
    let isOverideValid = true

    this.setState({
      errorMessageContractModal,
      isCancellationDateValid: moment(cancellationDate).isValid() ? true : false,
      isCancellationDateValidated: true,
    })
    if (hasOverideFlg) {
      this.setState({
        iscancellationFeeValid: !isNaN(parseFloat(cancellationFee)),
        iscancellationFeeValidated: true,
        isdealerRefundAmountValid: !isNaN(parseFloat(dealerRefundAmount)),
        isdealerRefundAmountValidated: true,
        iscustomerRefundAmountValid: !isNaN(parseFloat(customerRefundAmount)),
        iscustomerRefundAmountValidated: true,
      })
      isOverideValid =
        !isNaN(parseFloat(cancellationFee)) &&
        isFinite(cancellationFee) &&
        !isNaN(parseFloat(dealerRefundAmount)) &&
        isFinite(dealerRefundAmount) &&
        !isNaN(parseFloat(customerRefundAmount)) &&
        isFinite(customerRefundAmount)
    }

    return isOverideValid && moment(cancellationDate).isValid()
  }
  handleClose = () => {
    this.props.updateShowCancelContractModal(false)
    this.resetModal()
  }

  handleCancellationDateChange = async (date) => {
    this.setState({
      cancellationDate: moment(date).isValid() ? date : new Date(),
    })
  }
  resetModal = async () => {
    this.setState({
      cancellationDate: '',
      cancellationFee: '',
      dealerRefundAmount: '',
      customerRefundAmount: '',
      hasOverideFlg: false,
      isCancellationDateValid: null,
      isCancellationDateValidated: false,
      iscancellationFeeValid: null,
      iscancellationFeeValidated: false,
      isdealerRefundAmountValid: null,
      isdealerRefundAmountValidated: false,
      iscustomerRefundAmountValid: null,
      iscustomerRefundAmountValidated: false,
    })
  }

  resetValidation = async () => {
    this.setState({
      isCancellationDateValid: null,
      isCancellationDateValidated: false,
      iscancellationFeeValid: null,
      iscancellationFeeValidated: false,
      isdealerRefundAmountValid: null,
      isdealerRefundAmountValidated: false,
      iscustomerRefundAmountValid: null,
      iscustomerRefundAmountValidated: false,
    })
  }

  handleInputChange = async (event) => {
    if (event != null) {
      const { target } = event
      const { id, value } = target

      switch (id) {
        case 'cancellationDate':
          this.setState({
            [id]: value,
          })
          break
        case 'cancellationFee':
          this.setState({
            [id]: value,
          })
          break
        case 'dealerRefundAmount':
          this.setState({
            [id]: value,
          })
          break
        case 'customerRefundAmount':
          this.setState({
            [id]: value,
          })
          break
        default:
          this.setState({ [id]: value })
      }
    }
  }

  handleSubmit = async () => {
    let { cancellationDate, cancellationFee, dealerRefundAmount, customerRefundAmount } = this.state
    let cancelInfo = {
      cancellationDate,
      cancellationFee,
      dealerRefundAmount,
      customerRefundAmount,
    }
    this.props.handleCancelContract(cancelInfo)
    this.resetModal()
  }

  handleOpenConfiramtionDialog = () => {
    this.resetValidation()
    if (this.validateModal()) {
      this.setState({ showConfiramationDialog: true })
      this.props.updateShowCancelContractModal(false)
    }
  }

  handleConfirmation = () => {
    this.handleSubmit()
    this.setState({ showConfiramationDialog: false })
  }

  handleCloseConfiramtionDialog = () => {
    this.setState({ showConfiramationDialog: false })
    this.resetModal()
  }
  render() {
    const {
      cancellationDate,
      cancellationFee,
      dealerRefundAmount,
      customerRefundAmount,
      hasOverideFlg,
      isCancellationDateValid,
      isCancellationDateValidated,
      iscancellationFeeValid,
      iscancellationFeeValidated,
      isdealerRefundAmountValid,
      isdealerRefundAmountValidated,
      iscustomerRefundAmountValid,
      iscustomerRefundAmountValidated,
    } = this.state
    return (
      <Fragment>
        <Row>
          <Col md={12}></Col>
        </Row>
        <ConfirmationDialog
          show={this.state.showConfiramationDialog}
          onHide={this.handleCloseConfiramtionDialog}
          onConfirm={this.handleConfirmation}
          title='Cancel Contract'
          message='Are you sure you would like to cancel this contract?'
          confirmText='Yes'
          cancelText='No'
          confirmVariant='danger'
          cancelVariant='secondary'
        />
        <Modal show={this.props.showCancelContractModal} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Cancel Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} controlId='cancellationDate'>
              <Form.Label column xs={6}>
                Cancellation Date:
              </Form.Label>
              <br></br>
              <Col xs={6}>
                <DatePicker
                  calendarType='US'
                  className={
                    !isCancellationDateValidated
                      ? null
                      : isCancellationDateValid
                      ? 'is-valid'
                      : 'is-invalid'
                  }
                  clearIcon={null}
                  dayPlaceholder='DD'
                  monthPlaceholder='MM'
                  onChange={(date) => this.handleCancellationDateChange(date)}
                  required={true}
                  showLeadingZeros={true}
                  value={cancellationDate}
                  yearPlaceholder='YYYY'
                />

                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cancellation Date.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Check
              type='checkbox'
              onChange={() => {
                this.setState({ hasOverideFlg: !hasOverideFlg })
              }}
              label='Override'
              checked={hasOverideFlg}
              controlId='overRide'
            />
            {hasOverideFlg && (
              <Fragment>
                <Form.Group as={Row} controlId='cancellationFee'>
                  <Form.Label column xs={6}>
                    Cancellation Fee:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      autoComplete='off'
                      className={
                        !iscancellationFeeValidated
                          ? null
                          : iscancellationFeeValid
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                      onChange={(event) => {
                        this.handleInputChange(event)
                      }}
                      placeholder='Cancellation Fee'
                      size='sm'
                      type='text'
                      value={cancellationFee}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid Cancellation Fee.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='dealerRefundAmount'>
                  <Form.Label column xs={6}>
                    Dealer Refund Amount:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      autoComplete='off'
                      className={
                        !isdealerRefundAmountValidated
                          ? null
                          : isdealerRefundAmountValid
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                      onChange={(event) => {
                        this.handleInputChange(event)
                      }}
                      placeholder='Dealer Refund Amount'
                      size='sm'
                      type='text'
                      value={dealerRefundAmount}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid Dealer Refund Amount.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='customerRefundAmount'>
                  <Form.Label column xs={6}>
                    Customer Refund Amount:
                  </Form.Label>
                  <Col xs={6}>
                    <Form.Control
                      autoComplete='off'
                      className={
                        !iscustomerRefundAmountValidated
                          ? null
                          : iscustomerRefundAmountValid
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                      onChange={(event) => {
                        this.handleInputChange(event)
                      }}
                      placeholder='Customer Refund Amount'
                      size='sm'
                      type='text'
                      value={customerRefundAmount}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid Customer Refund Amount.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Fragment>
            )}
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center'>
            <Button variant='secondary' onClick={() => this.handleClose()}>
              Close
            </Button>
            <Button variant='primary' onClick={() => this.handleOpenConfiramtionDialog()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default CancelContractModal
