import React, { Component } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import Success from '../img/DialogIcon.svg'

export default class AppointmentSuccessDialog extends Component {
  render() {
    const {
      bookingContent,
      externalAppointmentNumber,
      selectedContract,
      handleCloseSoft,
      handleCloseForce,
      serviceOrderNumber,
      showAppointmentSuccess,
      softOrForceBook,
    } = this.props
    const { customer } = selectedContract
    const { email, firstName, lastName, phone } = customer
    return (
      <Dialog
        aria-labelledby='customized-dialog-title'
        open={showAppointmentSuccess}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '29rem',
            height: '35rem',
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: 'center' }}
          sx={{ m: 2, p: 2, mb: 0 }}
          id='customized-dialog-title'
        >
          <img
            src={Success}
            alt='user-pic'
            height='45px'
            width='45px'
            style={{ background: 'rgba(235, 248, 255, 1)', padding: '10px', borderRadius: '50%' }}
          />
        </DialogTitle>

        <DialogContent sx={{ textAlign: 'center' }}>
          <Grid>
            <span style={{ fontWeight: '600', fontSize: '15px' }}>
              {bookingContent?.serviceProvider} has been assigned to Service Order #
              {serviceOrderNumber}
              &nbsp; for {bookingContent?.availableDate}&nbsp;
              {externalAppointmentNumber && externalAppointmentNumber.split('-').length > 1 && (
                <p style={{ 'margin-bottom': '0' }}>
                  {'Order Number :'}
                  {externalAppointmentNumber.split('-')[1]}
                </p>
              )}
            </span>
          </Grid>

          {bookingContent?.serviceProvider === 'A&E' ? null : (
            <Grid style={{ marginTop: '2rem' }}>
              <span style={{ fontSize: '12px' }}>
                Dispatch notification has been sent to Service Provider &nbsp;{' '}
                <span style={{ fontSize: '12px', fontWeight: '600' }}>
                  {bookingContent?.serviceProvider} &nbsp;
                </span>
                at &nbsp;
                <span style={{ fontSize: '12px', fontWeight: '600' }}>
                  {bookingContent?.dispatchMethodPhone && (
                    <React.Fragment>
                      <br />
                      {bookingContent?.dispatchMethodPhone}
                    </React.Fragment>
                  )}
                  {bookingContent?.dispatchMethodEmail && (
                    <React.Fragment>
                      <br />
                      {bookingContent?.dispatchMethodEmail}
                    </React.Fragment>
                  )}
                </span>
              </span>
            </Grid>
          )}

          <Grid style={{ marginTop: '2rem' }}>
            <span style={{ fontSize: '12px' }}>
              Dispatch notification has been sent to Consumer &nbsp;
              <span style={{ fontSize: '12px', fontWeight: '600' }}>
                {firstName + ' ' + lastName} &nbsp;
              </span>
              at &nbsp;
              <span style={{ fontSize: '12px', fontWeight: '600' }}>
                {phone && (
                  <React.Fragment>
                    <br />
                    {phone}
                  </React.Fragment>
                )}
                {email && (
                  <React.Fragment>
                    <br />
                    {email}
                  </React.Fragment>
                )}
              </span>
            </span>
          </Grid>

          <br />
          <Grid item xs={12} style={{ justifyContent: 'center', marginTop: '1.8rem' }}>
            <Button
              variant='contained'
              onClick={() => {
                if (softOrForceBook === 'soft' || softOrForceBook === 'firm') {
                  handleCloseSoft()
                } else {
                  handleCloseForce()
                }
              }}
            >
              Okay
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}
