import React, { Component } from 'react'
import moment from 'moment'
import { Accordion, Card, Col, Row } from 'react-bootstrap'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Appointment from './Appointment'

class AppointmentDay extends Component {
  state = {
    appointment: [],
  }

  componentDidMount() {
    const { date } = this.props
    const { appointmentList } = date
    this.setState({
      appointment: appointmentList || [],
    })
  }

  getWeekDay() {
    let dayOfWeek = moment(this.props.date.date).day()
    switch (dayOfWeek) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
      default:
        return ''
    }
  }

  displayAppointments(appointment) {
    const {
      bookedAppointment,
      handleAppointmentSelect,
      selectedAppointment,
      showPopupAppointmentDetail,
    } = this.props

    let groupedAppointment = []
    for (const item of appointment) {
      let index = groupedAppointment.findIndex((x) => x.svcrName === item.appointmentInfo.svcrName)
      if (index >= 0) {
        groupedAppointment[index].appointments.push(item)
      } else {
        groupedAppointment.push({
          type: item.appointmentInfo.svcrType,
          svcrName: item.appointmentInfo.svcrName,
          appointments: [item],
        })
      }
    }
    return groupedAppointment.map((appointment, i) => {
      return (
        <Appointment
          appointment={appointment}
          handleAppointmentSelect={handleAppointmentSelect}
          selectedAppointment={selectedAppointment}
          bookedAppointment={bookedAppointment}
          type={appointment.type}
          servicer={appointment.svcrName}
          appointments={appointment.appointments}
          index={i}
          showPopupAppointmentDetail={showPopupAppointmentDetail}
        />
      )
    })
  }

  render() {
    const { appointment } = this.state

    const { date, handleNextWeek, handleLastWeek, index, total, showLast } = this.props

    return (
      <Col className='pl-0 pr-0'>
        <Card className='text-center mb-2 bg-white border-0'>
          <Card.Header className='text-dark bg-light border-0' style={{ fontSize: '.7rem' }}>
            <Row>
              {showLast && index === 0 && (
                <Col className='pl-0 pr-0' xs={2}>
                  <FontAwesomeIcon
                    className='mt-2 w-100'
                    size='1x'
                    icon={faAngleLeft}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleLastWeek()}
                  />
                </Col>
              )}
              <Col>
                {this.getWeekDay()}
                <br />
                {date.date}
              </Col>
              {index === total - 1 && (
                <Col className='pl-0 pr-0' xs={2}>
                  <FontAwesomeIcon
                    className='mt-2 w-100'
                    size='1x'
                    icon={faAngleRight}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNextWeek()}
                  />
                </Col>
              )}
            </Row>
          </Card.Header>
          <Accordion
            className='mt-2 h-100 border-right-0 border-left-0'
            style={{ fontSize: '.6rem' }}
          >
            {this.displayAppointments(appointment)}
          </Accordion>
        </Card>
      </Col>
    )
  }
}

export default AppointmentDay
