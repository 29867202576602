import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  createContract,
  getCountryList,
  getNewContractModels,
  getStateDetail,
  getSubcategoriesByBrand,
} from '../api/serviceavenger'
import ContractCustomer from './ContractCustomer'
import ContractProduct from './ContractProduct'
import { CASE_FIELD_CLAIMS } from '../constants'
import moment from 'moment'
import parse from 'html-react-parser'

class ContractModal extends Component {
  state = {
    address: '',
    address2: '',
    city: '',
    contractType: 'OEM Warranty',
    email: this.props.kustomerEmail,
    errorMessageContractModal: '',
    firstName: '',
    installDt: new Date(),
    isAddressValid: null,
    isAddressValidated: false,
    isCityValid: null,
    isCityValidated: false,
    isEmailValid: null,
    isEmailValidated: false,
    isFirstNameValid: null,
    isFirstNameValidated: false,
    isInstallDtValid: null,
    isInstallDtValidated: false,
    isLastNameValid: null,
    isLastNameValidated: false,
    isNewBrandValid: null,
    isNewBrandValidated: false,
    isNewModelValid: null,
    isNewModelValidated: false,
    isNewSerialValid: null,
    isNewSerialValidated: false,
    isNewSubcategoryValid: null,
    isNewSubcategoryValidated: false,
    isPostalCodeValid: null,
    isPostalCodeValidated: false,
    isPurchaseDtValid: null,
    isPurchaseDtValidated: false,
    isStateValid: null,
    isCountryValid: null,
    isStateValidated: false,
    isCountryValidated: false,
    isPrimaryPhoneValid: null,
    isPrimaryPhoneValidated: false,
    isSecondaryPhoneValid: null,
    isSecondaryPhoneValidated: false,
    lastName: '',
    manufacturers: [
      { brand: 'Bertazzoni', brandCode: 'Bertazzoni' },
      { brand: 'OPAL - Amazon', brandCode: 'OPAL - Amazon' },
      { brand: 'OPAL - Best Buy', brandCode: 'OPAL - Best Buy' },
      { brand: 'OPAL - COSTCO', brandCode: 'OPAL - COSTCO' },
      { brand: 'OPAL - Facebook', brandCode: 'OPAL - Facebook' },
      { brand: 'OPAL - GE Appliances', brandCode: 'OPAL - GE Appliances' },
      { brand: 'OPAL - Gift', brandCode: 'OPAL - Gift' },
      { brand: 'OPAL - Lowes', brandCode: 'OPAL - Lowes' },
      { brand: 'OPAL - Other', brandCode: 'OPAL - Other' },
      { brand: 'OPAL - The Home Depot', brandCode: 'OPAL - The Home Depot' },
      { brand: 'OPAL - Walmart', brandCode: 'OPAL - Walmart' },
      { brand: 'OPAL - Williams-Sonoma', brandCode: 'OPAL - Williams-Sonoma' },
      { brand: 'Superiore', brandCode: 'Superiore' },
      { brand: 'THOR', brandCode: 'THOR' },
      { brand: 'ZLINE', brandCode: 'ZLINE' },
    ],
    newBrand: '',
    newModel: '',
    newSerial: '',
    newSubcategory: '',
    caseNumberOne: '',
    caseNumberTwo: '',
    oemManufacturers: [
      { brand: 'Bertazzoni', brandCode: 'Bertazzoni' },
      { brand: 'OPAL - Amazon', brandCode: 'OPAL - Amazon' },
      { brand: 'OPAL - Best Buy', brandCode: 'OPAL - Best Buy' },
      { brand: 'OPAL - COSTCO', brandCode: 'OPAL - COSTCO' },
      { brand: 'OPAL - Facebook', brandCode: 'OPAL - Facebook' },
      { brand: 'OPAL - GE Appliances', brandCode: 'OPAL - GE Appliances' },
      { brand: 'OPAL - Gift', brandCode: 'OPAL - Gift' },
      { brand: 'OPAL - Lowes', brandCode: 'OPAL - Lowes' },
      { brand: 'OPAL - Other', brandCode: 'OPAL - Other' },
      { brand: 'OPAL - The Home Depot', brandCode: 'OPAL - The Home Depot' },
      { brand: 'OPAL - Walmart', brandCode: 'OPAL - Walmart' },
      { brand: 'OPAL - Williams-Sonoma', brandCode: 'OPAL - Williams-Sonoma' },
      { brand: 'Superiore', brandCode: 'Superiore' },
      { brand: 'THOR', brandCode: 'THOR' },
      { brand: 'ZLINE', brandCode: 'ZLINE' },
    ],
    postalCode: '',
    primaryPhone: this.props.kustomerPhone,
    purchaseDt: new Date(),
    secondaryPhone: '',
    stateProvince: '',
    subCatCode: '',
    subcategories: [],
    models: [],
    country: '',
    countryList: [],
    stateList: [],
    countryId: '',
    countyCode: '',
    stateCode: '',
  }

  componentDidMount = async () => {
    const { newBrand, newSubcategory } = this.props
    this.getSubcategories(newBrand)
    this.getModels(newBrand, newSubcategory)
    this.getCountryList()
  }
  getCountryList = async () => {
    try {
      const response = await getCountryList()
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ countryList: data?.items || [] })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting CountryList:</b><br/>${error.message}`,
      })
    }
  }
  getStateList = async (countryId) => {
    try {
      const response = await getStateDetail(countryId)
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ stateList: data?.items || [] })
        return data?.items
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting stateList:</b><br/>${error.message}`,
      })
    }
  }
  getSubcategories = async (brand) => {
    let errorMessageContractModal = ''
    let response = {}

    if (brand?.length > 0) {
      try {
        response = await getSubcategoriesByBrand(brand)
        const { status, data, message } = response

        if (status === 200) {
          if (data.status !== 200) {
            errorMessageContractModal += `<b>Error while getting subcategory list:</b><br/>${data?.status} - ${data?.message}`
            let errorDetails = data?.data?.details

            if (errorDetails?.length > 0) {
              errorMessageContractModal += '<ul>'
              errorDetails.forEach((error) => {
                errorMessageContractModal += `<li>${error?.code} - ${error?.message}</li>`
              })
              errorMessageContractModal += '</ul>'
            } else {
              errorDetails = data?.data
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessageContractModal += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessageContractModal += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessageContractModal += '</ul>'
                })
              }
            }

            this.setState({ errorMessageContractModal })
            window.location.href = '#modalErrorFound'
            return null
          } else {
            return response.data.data
          }
        } else {
          errorMessageContractModal = this.state.errorMessageContractModal
          errorMessageContractModal += `<br/><b>Error while getting subcategory details:</b><br/> ${status} - ${message}`
          this.setState({ errorMessageContractModal })
          window.location.href = '#modalErrorFound'
          return null
        }
      } catch (error) {
        this.setState({
          errorMessageContractModal: `<br/><b>Error while getting subcategories:</b><br/>${error.message}`,
        })
      }

      window.location.href = '#modalErrorFound'
      return null
    }
  }

  getModels = async (brand, category) => {
    if (!brand || !category) {
      return []
    }

    try {
      let errorMessageContractModal = ''

      const response = await getNewContractModels(brand, category)

      const { status, data, message } = response

      if (status === 200) {
        if (data.status !== 200) {
          errorMessageContractModal += `<b>Error while getting model number list:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessageContractModal += '<ul>'
            errorDetails.forEach((error) => {
              errorMessageContractModal += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessageContractModal += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessageContractModal += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessageContractModal += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessageContractModal += '</ul>'
              })
            }
          }

          this.setState({ errorMessageContractModal })
          window.location.href = '#modalErrorFound'
          return null
        } else {
          return data.data
        }
      } else {
        errorMessageContractModal = this.state.errorMessageContractModal
        errorMessageContractModal += `<br/><b>Error while getting model number:</b><br/> ${status} - ${message}`
        this.setState({ errorMessageContractModal })
        window.location.href = '#modalErrorFound'
        return []
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting model numbers:</b><br/>${error.message}`,
      })
      window.location.href = '#modalErrorFound'
      return []
    }
  }

  handleContractCreate = async () => {
    const { handleNewContractDetails } = this.props
    const {
      address,
      address2,
      city,
      email,
      firstName,
      installDt,
      lastName,
      postalCode,
      primaryPhone,
      newBrand,
      newModel,
      newSerial,
      newSubcategory,
      purchaseDt,
      secondaryPhone,
      subCatCode,
      caseNumberOne,
      caseNumberTwo,
      stateCode,
      countyCode,
    } = this.state

    let errorMessageContractModal = ''

    let dealerInvoiceNumber = ''
    if (CASE_FIELD_CLAIMS.includes(newBrand)) {
      if (caseNumberOne) {
        dealerInvoiceNumber = `${newBrand}_${caseNumberOne}`
      }
      if (caseNumberTwo) {
        if (caseNumberOne) {
          dealerInvoiceNumber = `${dealerInvoiceNumber}_${caseNumberTwo}`
        } else {
          dealerInvoiceNumber = `${newBrand}_${caseNumberTwo}`
        }
      }
    }

    if (dealerInvoiceNumber === '') {
      dealerInvoiceNumber = newSerial + new Date().toISOString()
    }

    try {
      const response = await createContract(
        firstName,
        lastName,
        address,
        address2,
        city,
        stateCode,
        postalCode,
        primaryPhone?.replace(/\D/g, ''),
        secondaryPhone?.replace(/\D/g, ''),
        email,
        newSubcategory,
        newBrand,
        newModel,
        newSerial,
        purchaseDt,
        installDt,
        subCatCode,
        dealerInvoiceNumber,
        countyCode,
      )

      if (response.status === 200) {
        if (response.data.status !== 200) {
          const { data } = response
          errorMessageContractModal += `<br/><b>Error attempting to create contract:</b><br/>${data.status} - ${data.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessageContractModal += '<ul>'
            errorDetails.forEach((error) => {
              errorMessageContractModal += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessageContractModal += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessageContractModal += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessageContractModal += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessageContractModal += '</ul>'
              })
            }
          }

          this.setState({ errorMessageContractModal })
          window.location.href = '#modalErrorFound'
        } else {
          let contractNumber = response.data.opwContractID
          this.handleContractCreateClose()
          handleNewContractDetails(contractNumber)
        }
      } else {
        errorMessageContractModal += `<br/><b>Error creating contract:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessageContractModal })
        window.location.href = '#modalErrorFound'
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error attempting to create contract:</b><br/>${error.message}`,
        appointmentWorking: false,
      })
      window.location.href = '#modalErrorFound'
    }
  }

  handleContractCreateClose = () => {
    this.props.updateShowNewContractModal(false)
    this.resetModal()
  }

  handleContractCreateShow = () => {
    this.resetModal()
  }

  handleContractTypeChange = (event) => {
    const { target } = event
    const { id, value } = target

    let manufacturers = value === 'OEM Warranty' ? this.state.oemManufacturers : []

    this.setState({
      [id]: value,
      manufacturers,
    })
  }

  handleInstallDtChange = async (date) => {
    this.setState({
      installDt: moment(date).isValid() ? date : new Date(),
    })
  }

  handlePurchaseDtChange = async (date) => {
    this.setState({
      purchaseDt: moment(date).isValid() ? date : new Date(),
    })
  }

  handleInputChange = async (event) => {
    this.resetValidation()
    if (event != null) {
      const { target } = event
      const { id, value } = target

      switch (id) {
        case 'newBrand':
          const subcategoryList = value.length > 0 ? await this.getSubcategories(value) : []

          this.setState({
            [id]: value,
            newSubcategory: '',
            subcategories: subcategoryList,
            models: [],
            newModel: '',
          })
          break
        case 'newSubcategory':
          const subCategory = this.state.subcategories.find(function (subcategory) {
            return subcategory.productSubCategoryDescription.toUpperCase() === value?.toUpperCase()
          })

          let subCategoryCode = value.length > 0 ? subCategory.subCategoryCode : ''

          let modelsList = await this.getModels(
            this.state.newBrand,
            subCategory?.productSubCategoryDescription,
          )

          this.setState({
            [id]: value,
            subCatCode: subCategoryCode,
            newModel: '',
            models: modelsList,
          })
          break
        case 'primaryPhone':
          this.setState({
            [id]: value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          })
          break
        case 'purchaseDt':
          this.setState({
            [id]: moment(value).isValid() ? value : new Date(),
          })
          break
        case 'secondaryPhone':
          this.setState({
            [id]: value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          })
          break
        case 'full-address':
          const filterSelectedCountry = this.state.countryList?.find(
            (item) => item?.regionCode === value?.country,
          )
          this.setState(
            {
              city: value.city,
              postalCode: value.postalCode,
              stateCode: value?.state,
              country: filterSelectedCountry?.countryName,
              countryId: filterSelectedCountry?.countryId,
              countyCode: filterSelectedCountry?.countryCode,
            },
            async () => {
              if (filterSelectedCountry?.countryId) {
                const stateList = await this.getStateList(filterSelectedCountry?.countryId)
                const filteredState = stateList?.find((item) => item?.stateCode === value?.state)
                if (!!filteredState) {
                  this.setState({
                    stateProvince: filteredState?.stateName,
                  })
                }
              }
            },
          )
          break
        case 'country':
          const selectedCountry = this.state.countryList?.find(
            (item) => item?.countryName === value,
          )
          if (!!selectedCountry) {
            this.getStateList(selectedCountry?.countryId || '')
          }

          this.setState({
            country: value,
            countryId: selectedCountry?.countryId,
            countyCode: selectedCountry?.countryCode,
          })
          break
        case 'state/Province':
          const selectedState = this.state.stateList?.find((item) => item?.stateName === value)
          this.setState({
            stateProvince: value,
            stateCode: selectedState?.stateCode,
          })
          break
        default:
          this.setState({ [id]: value })
      }
    }
  }

  handleSubmit = async () => {
    this.resetValidation()
    if (this.validateModal()) {
      this.handleContractCreate()
    }
  }

  resetModal = () => {
    this.setState({
      manufacturers: [
        { brand: 'Bertazzoni', brandCode: 'Bertazzoni' },
        { brand: 'OPAL - Amazon', brandCode: 'OPAL - Amazon' },
        { brand: 'OPAL - Best Buy', brandCode: 'OPAL - Best Buy' },
        { brand: 'OPAL - COSTCO', brandCode: 'OPAL - COSTCO' },
        { brand: 'OPAL - Facebook', brandCode: 'OPAL - Facebook' },
        { brand: 'OPAL - GE Appliances', brandCode: 'OPAL - GE Appliances' },
        { brand: 'OPAL - Gift', brandCode: 'OPAL - Gift' },
        { brand: 'OPAL - Lowes', brandCode: 'OPAL - Lowes' },
        { brand: 'OPAL - Other', brandCode: 'OPAL - Other' },
        { brand: 'OPAL - The Home Depot', brandCode: 'OPAL - The Home Depot' },
        { brand: 'OPAL - Walmart', brandCode: 'OPAL - Walmart' },
        {
          brand: 'OPAL - Williams-Sonoma',
          brandCode: 'OPAL - Williams-Sonoma',
        },
        { brand: 'Superiore', brandCode: 'Superiore' },
        { brand: 'THOR', brandCode: 'THOR' },
        { brand: 'ZLINE', brandCode: 'ZLINE' },
        { brand: 'ZLINE-RECALL', brandCode: 'ZLINE-RECALL' },
      ],
      newBrand: '',
      newModel: '',
      newSerial: '',
      newSubcategory: '',
      caseNumberOne: '',
      caseNumberTwo: '',
      subcategories: [],
      contractType: 'OEM Warranty',
      address: '',
      address2: '',
      city: '',
      email: this.props.kustomerEmail,
      firstName: '',
      installDt: new Date(),
      isAddressValid: null,
      isAddressValidated: false,
      isCityValid: null,
      isCityValidated: false,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isFirstNameValidated: false,
      isInstallDtValid: null,
      isInstallDtValidated: false,
      isLastNameValid: null,
      isLastNameValidated: false,
      isNewBrandValid: null,
      isNewBrandValidated: false,
      isNewModelValid: null,
      isNewModelValidated: false,
      isNewSerialValid: null,
      isNewSerialValidated: false,
      isNewSubcategoryValid: null,
      isNewSubcategoryValidated: false,
      isPostalCodeValid: null,
      isPostalCodeValidated: false,
      isPurchaseDtValid: null,
      isPurchaseDtValidated: false,
      isStateValid: null,
      isCountryValid: null,
      isStateValidated: false,
      isCountryValidated: false,
      isPrimaryPhoneValid: null,
      isPrimaryPhoneValidated: false,
      isSecondaryPhoneValid: null,
      isSecondaryPhoneValidated: false,
      lastName: '',
      postalCode: '',
      primaryPhone: this.props.kustomerPhone,
      purchaseDt: new Date(),
      secondaryPhone: '',
      stateProvince: '',
      subCatCode: '',
      country: '',
    })
  }

  resetValidation = () => {
    this.setState({
      isAddressValid: null,
      isAddressValidated: false,
      isCityValid: null,
      isCityValidated: false,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isFirstNameValidated: false,
      isInstallDtValid: null,
      isInstallDtValidated: false,
      isLastNameValid: null,
      isLastNameValidated: false,
      isNewBrandValid: null,
      isNewBrandValidated: false,
      isNewModelValid: null,
      isNewModelValidated: false,
      isNewSerialValid: null,
      isNewSerialValidated: false,
      isNewSubcategoryValid: null,
      isNewSubcategoryValidated: false,
      isPostalCodeValid: null,
      isPostalCodeValidated: false,
      isPurchaseDtValid: null,
      isPurchaseDtValidated: false,
      isStateValid: null,
      isCountryValid: null,
      isStateValidated: false,
      isCountryValidated: false,
      isPrimaryPhoneValid: null,
      isPrimaryPhoneValidated: false,
      isSecondaryPhoneValid: null,
      isSecondaryPhoneValidated: false,
    })
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, '')
    return cleanString?.length === 10
  }

  validateModal = () => {
    const {
      address,
      city,
      email,
      firstName,
      installDt,
      lastName,
      newBrand,
      newModel,
      newSerial,
      newSubcategory,
      postalCode,
      primaryPhone,
      purchaseDt,
      secondaryPhone,
      stateProvince,
      country,
    } = this.state

    let errorMessageContractModal = ''

    this.setState({
      errorMessageContractModal,
      isAddressValid: address?.length > 0 ? true : false,
      isAddressValidated: true,
      isCityValid: city?.length > 0 ? true : false,
      isCityValidated: true,
      isEmailValid: email?.trim().length === 0 ? false : this.validateEmail(email?.trim()),
      isEmailValidated: true,
      isFirstNameValid: firstName?.length > 0 ? true : false,
      isFirstNameValidated: true,
      isInstallDtValid: moment(installDt).isValid() ? true : false,
      isInstallDtValidated: true,
      isLastNameValid: lastName?.length > 0 ? true : false,
      isLastNameValidated: true,
      isNewBrandValid: newBrand?.length > 0 ? true : false,
      isNewBrandValidated: true,
      isNewModelValid: newModel?.length > 0 ? true : false,
      isNewModelValidated: true,
      isNewSerialValid: newSerial?.length > 0 ? true : false,
      isNewSerialValidated: true,
      isNewSubcategoryValid: newSubcategory?.length > 0 ? true : false,
      isNewSubcategoryValidated: true,
      isPostalCodeValid: postalCode?.length < 11 ? true : false,
      isPostalCodeValidated: true,
      isPrimaryPhoneValid:
        primaryPhone?.trim().length === 0 ? false : this.validatePhone(primaryPhone?.trim()),
      isPrimaryPhoneValidated: true,
      isPurchaseDtValid: moment(purchaseDt).isValid() ? true : false,
      isPurchaseDtValidated: true,
      isSecondaryPhoneValid:
        secondaryPhone?.trim().length > 0 ? this.validatePhone(secondaryPhone?.trim()) : null,
      isSecondaryPhoneValidated: secondaryPhone?.trim().length > 0 ? true : false,
      isStateValid: stateProvince?.length > 0 ? true : false,
      isCountryValid: country?.length > 0 ? true : false,

      isStateValidated: true,
      isCountryValidated: true,
    })

    return (
      address?.length > 0 &&
      city?.length > 0 &&
      this.validateEmail(email) &&
      firstName?.length > 0 &&
      moment(installDt).isValid() &&
      lastName?.length > 0 &&
      newBrand?.length > 0 &&
      newModel?.length > 0 &&
      newSerial?.length > 0 &&
      newSubcategory?.length > 0 &&
      postalCode?.length > 0 &&
      primaryPhone?.replace(/\D/g, '').length === 10 &&
      moment(purchaseDt).isValid() &&
      stateProvince?.length > 0 &&
      (secondaryPhone.length === 0 ||
        (secondaryPhone.length > 0 && this.validatePhone(secondaryPhone?.trim())))
    )
  }

  render() {
    const {
      address,
      address2,
      city,
      contractType,
      email,
      errorMessageContractModal,
      firstName,
      installDt,
      isAddressValid,
      isAddressValidated,
      isCityValid,
      isCityValidated,
      isEmailValid,
      isEmailValidated,
      isFirstNameValid,
      isFirstNameValidated,
      isInstallDtValid,
      isInstallDtValidated,
      isLastNameValid,
      isLastNameValidated,
      isNewBrandValid,
      isNewBrandValidated,
      isNewModelValid,
      isNewModelValidated,
      isNewSerialValid,
      isNewSerialValidated,
      isNewSubcategoryValid,
      isNewSubcategoryValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isPrimaryPhoneValid,
      isPrimaryPhoneValidated,
      isPurchaseDtValid,
      isPurchaseDtValidated,
      isSecondaryPhoneValid,
      isSecondaryPhoneValidated,
      isStateValid,
      isCountryValid,

      isStateValidated,
      isCountryValidated,
      lastName,
      manufacturers,
      newBrand,
      newModel,
      newSerial,
      newSubcategory,
      caseNumberOne,
      caseNumberTwo,
      postalCode,
      primaryPhone,
      purchaseDt,
      secondaryPhone,
      stateProvince,
      subcategories,
      models,
      countryList,
      country,
      stateList,
    } = this.state
    return (
      <Fragment>
        <Row>
          <Col md={12}></Col>
        </Row>
        <Modal show={this.props.showNewContractModal} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Create New Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='contractType' className='mb-3' as={Row}>
              <Form.Label column xs={6}>
                Contract Type:
              </Form.Label>
              <Col xs={6}>
                <Form.Control
                  as='select'
                  size='sm'
                  value={contractType}
                  onChange={(event) => {
                    this.handleContractTypeChange(event)
                  }}
                >
                  <option key='OEM Warranty' value='OEM Warranty'>
                    OEM Warranty
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <ContractCustomer
              address={address}
              address2={address2}
              city={city}
              email={email}
              firstName={firstName}
              handleInputChange={this.handleInputChange}
              isAddressValid={isAddressValid}
              isAddressValidated={isAddressValidated}
              isCityValid={isCityValid}
              isCityValidated={isCityValidated}
              isEmailValid={isEmailValid}
              isEmailValidated={isEmailValidated}
              isFirstNameValid={isFirstNameValid}
              isFirstNameValidated={isFirstNameValidated}
              isLastNameValid={isLastNameValid}
              isLastNameValidated={isLastNameValidated}
              isPostalCodeValid={isPostalCodeValid}
              isPostalCodeValidated={isPostalCodeValidated}
              isPrimaryPhoneValid={isPrimaryPhoneValid}
              isPrimaryPhoneValidated={isPrimaryPhoneValidated}
              isSecondaryPhoneValid={isSecondaryPhoneValid}
              isSecondaryPhoneValidated={isSecondaryPhoneValidated}
              isStateValid={isStateValid}
              isCountryValid={isCountryValid}
              isStateValidated={isStateValidated}
              isCountryValidated={isCountryValidated}
              lastName={lastName}
              postalCode={postalCode}
              primaryPhone={primaryPhone}
              secondaryPhone={secondaryPhone}
              stateProvince={stateProvince}
              country={country}
              countryList={countryList}
              stateList={stateList}
            ></ContractCustomer>
            <ContractProduct
              handleInputChange={this.handleInputChange}
              handleInstallDtChange={this.handleInstallDtChange.bind(this)}
              handlePurchaseDtChange={this.handlePurchaseDtChange.bind(this)}
              isInstallDtValid={isInstallDtValid}
              isInstallDtValidated={isInstallDtValidated}
              isNewBrandValid={isNewBrandValid}
              isNewBrandValidated={isNewBrandValidated}
              isNewModelValid={isNewModelValid}
              isNewModelValidated={isNewModelValidated}
              isNewSerialValid={isNewSerialValid}
              isNewSerialValidated={isNewSerialValidated}
              isNewSubcategoryValid={isNewSubcategoryValid}
              isNewSubcategoryValidated={isNewSubcategoryValidated}
              isPurchaseDtValid={isPurchaseDtValid}
              isPurchaseDtValidated={isPurchaseDtValidated}
              manufacturers={manufacturers}
              newBrand={newBrand}
              newModel={newModel}
              newSerial={newSerial}
              newSubcategory={newSubcategory}
              subcategories={subcategories}
              caseNumberOne={caseNumberOne}
              caseNumberTwo={caseNumberTwo}
              models={models}
              purchaseDt={purchaseDt}
              installDt={installDt}
            ></ContractProduct>
            {errorMessageContractModal.length > 0 ? (
              <Row className='mt-3 ps-3 pe-3'>
                <Col>
                  <Alert id='errorFound' variant='danger'>
                    {parse(errorMessageContractModal)}
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.handleContractCreateClose()}>
              Close
            </Button>
            <Button variant='primary' onClick={() => this.handleSubmit()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default ContractModal
