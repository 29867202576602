import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import Delivery from '../../img/delivery-box (1) 1.svg'
import './../App.css'

export default function RescheduleAppointmentDialog({
  open,
  rescheduleAppointmentDialogData,
  handleClose,
  servicer,
  bookReschedule,
  openTimeSlot,
  closeTimeSlot,
  openTimeSlotDialog,
  id,
  uiservicerData,
}) {
  const CardsContetnStyle = {
    fontSize: '12px',
  }

  const [selectedData, setSelectedData] = useState()
  const [selectedSlot, setSelectedSlot] = useState()
  const [bookingNote, setBookingNote] = useState('')
  useEffect(() => {
    setBookingNote('')
  }, [])

  const displayTimeSlotDataAandE = (row, item) => {
    setSelectedData(row)
    setSelectedSlot(item.slotName)
    openTimeSlot()
  }

  const displayTimeSlotDataSoft = (row, item) => {
    setSelectedData(row)
    setSelectedSlot(item)
    openTimeSlot()
  }

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '50rem',
            height: '40rem',
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: 'center' }}
          sx={{ m: 1, p: 1, mb: 0 }}
          id='customized-dialog-title'
        >
          Reschedule Appointment
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 100 }}>
              Service Provider: {uiservicerData?.account?.businessName}
            </span>{' '}
            <span style={{ fontWeight: 100 }}>Service Provider ID: {id}</span>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            height: '25px',
            width: '25px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TableContainer className='custom-scrollbar' sx={{ maxHeight: '50vh' }}>
            <Table
              aria-label='simple table'
              stickyHeader
              sx={{ '& .MuiTableCell-root': { lineHeight: '1.27rem', color: '#233849' } }}
            >
              <TableHead
                sx={{
                  '&.MuiTableHead-root .MuiTableCell-root': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    backgroundColor: '#F2F4FF',
                  },
                }}
              >
                <TableRow sx={{ color: '#233849' }}>
                  <TableCell
                    sx={{
                      textAlign: 'left',
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#233849',
                    }}
                  >
                    Days of the week
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#233849',
                    }}
                  >
                    Time Band
                  </TableCell>
                  {servicer?.providerType === 'A&E' ? null : (
                    <React.Fragment>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          fontWeight: '600',
                          fontSize: '14px',
                          color: '#233849',
                        }}
                      >
                        Same Day Cut Off
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          fontWeight: '600',
                          fontSize: '14px',
                          color: '#233849',
                        }}
                      >
                        Next Day Cut Off
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {servicer?.providerType === 'A&E'
                  ? rescheduleAppointmentDialogData?.map((row, index) => (
                      <TableRow key={row?.date}>
                        <TableCell sx={{ color: '#5C678F', fontWeight: '600' }}>
                          {row?.day + ' ' + row?.dateObject.format('MMM D')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }}>
                          {row?.timeBand[0]?.timeSlot?.length > 0
                            ? row?.timeBand[0]?.timeSlot.map((item) => (
                                <React.Fragment>
                                  {' '}
                                  <span
                                    onClick={() => {
                                      displayTimeSlotDataAandE(row, item)
                                    }}
                                    style={{
                                      ...CardsContetnStyle,
                                      textTransform: 'capitalize',
                                      background: ' rgba(87, 189, 248, 0.16)',
                                      color: 'rgba(2, 160, 252, 1)',
                                      borderRadius: '5px',
                                      padding: '1px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {' '}
                                    {item.slotName}{' '}
                                  </span>
                                  &nbsp;&nbsp;
                                </React.Fragment>
                              ))
                            : null}
                        </TableCell>
                      </TableRow>
                    ))
                  : rescheduleAppointmentDialogData?.map((row) => (
                      <TableRow key={row?.date}>
                        <TableCell sx={{ color: '#5C678F', fontWeight: '600' }}>
                          {row?.day + ' ' + row?.dateObject.format('MMM D')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'left', cursor: 'pointer' }}>
                          {row?.timeBand?.length > 0
                            ? row?.timeBand.map((item) => (
                                <React.Fragment>
                                  {' '}
                                  <span
                                    onClick={() => {
                                      displayTimeSlotDataSoft(row, item)
                                    }}
                                    style={{
                                      ...CardsContetnStyle,
                                      textTransform: 'capitalize',
                                      background: ' rgba(87, 189, 248, 0.16)',
                                      color: 'rgba(2, 160, 252, 1)',
                                      borderRadius: '5px',
                                      padding: '1px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {' '}
                                    {item}{' '}
                                  </span>
                                  &nbsp;&nbsp;
                                </React.Fragment>
                              ))
                            : null}
                        </TableCell>
                        <TableCell align='center' sx={{ padding: 0 }}>
                          {row?.sameDayCutOff} {row?.sameDay}
                        </TableCell>
                        <TableCell align='center' sx={{ padding: 0 }}>
                          {row?.nextDayCutOff} {row?.nextDay}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid
            item
            xs={12}
            style={{ justifyContent: 'center', marginTop: '0px', marginBottom: '1rem' }}
          >
            <Button variant='contained' onClick={handleClose} style={{ marginLeft: '80%' }}>
              Cancel
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={closeTimeSlot}
        aria-labelledby='customized-dialog-title'
        open={openTimeSlotDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '26rem',
            height: '30.5rem',
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: 'center', fontSize: '16px' }}
          sx={{ m: 1, p: 1, mb: 0 }}
          id='customized-dialog-title'
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img
              src={Delivery}
              alt='user-pic'
              height='45px'
              width='45px'
              style={{
                background: 'rgba(235, 248, 255, 1)',
                padding: '10px',
                borderRadius: '50%',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              Do you want to Reschedule Appointment for{' '}
              {servicer?.providerType === 'A&E'
                ? selectedData?.day + ', ' + moment(selectedData?.date).format('MMMM DD, YYYY')
                : selectedData?.day +
                  ', ' +
                  moment(selectedData?.date).format('MMMM DD, YYYY')}{' '}
            </span>
          </div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => {
            closeTimeSlot()
            setBookingNote('')
          }}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            height: '29px',
            width: '25px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          sx={{
            width: '90%',
            margin: 'auto',
            marginTop: '10px',
            marginBottom: '25px',
            borderRadius: '20px',
          }}
        >
          <Paper elevation={0} sx={{ borderRadius: '18px', overflow: 'hidden' }}>
            <Grid
              style={{
                textAlign: 'center',
                height: '2.6rem',
                background: 'rgba(0, 161, 255, 1)',
                color: 'white',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {servicer?.providerType === 'A&E' ? 'A&E' : selectedData?.servicerName}
            </Grid>
            <Grid
              style={{
                background: 'rgba(242, 244, 255, 1)',
                padding: '0.5rem 1rem',
                textAlign: 'left',
                borderRadius: '0px 0px 15px 15px',
              }}
            >
              <span style={{ marginBottom: '1rem', fontSize: '14px' }}>
                <strong>Date:</strong>{' '}
                {servicer?.providerType === 'A&E' ? selectedData?.date : selectedData?.date}
              </span>
              <br />
              <span style={{ marginBottom: '1rem', fontSize: '14px' }}>
                <strong>Time:</strong>{' '}
                {servicer?.providerType === 'A&E' ? selectedSlot : selectedSlot}
              </span>
              <br />
            </Grid>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextField
                aria-label='minimum height'
                value={bookingNote}
                minRows={6}
                maxRows={8}
                placeholder='Enter Notes Here'
                onChange={(event) => {
                  setBookingNote(event.target.value)
                }}
              />
            </div>

            <Grid container justifyContent='center' style={{ marginTop: '15px' }}>
              <Button
                variant='outlined'
                onClick={() => {
                  closeTimeSlot()
                  setBookingNote('')
                }}
              >
                No
              </Button>
              <Button
                variant='contained'
                onClick={() =>
                  bookReschedule(selectedData, selectedSlot, bookingNote, setBookingNote)
                }
                style={{ marginLeft: '20px' }}
              >
                Yes
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Dialog>
    </React.Fragment>
  )
}
