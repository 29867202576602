import React, { Component } from 'react'
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';


export default class ForceBook extends Component {
  state = {
    searchTerm: '',
    selectedOption: 'servicerName',
  };

  handleSearchInputChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleRadioChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  handleSearch = () => {
    this.props.findForceBook(this.state.selectedOption, this.state.searchTerm)
  };

  render() {
    const { appointmentWorking } = this.props

    return (
      <div className="container">
        <Form className="p-0">
          <Form.Group as={Row} controlId="formSearch">
            <Col xs={5}>
              <Form.Control
                type="text"
                placeholder="Enter Servicer Name or Id"
                value={this.state.searchTerm}
                onChange={this.handleSearchInputChange}
              />
            </Col>
            <Col xs={2}>
              <Button variant="primary" onClick={this.handleSearch}>
                Search
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formRadio" className="mt-2 w-50">
            <Col className="d-flex gap-1">
              <Form.Check
                type="radio"
                label="Servicer Name"
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                value="servicerName"
                checked={this.state.selectedOption === 'servicerName'}
                onChange={this.handleRadioChange}

              />
            </Col>
            <Col className="d-flex gap-1">
              <Form.Check
                type="radio"
                label="Servicer Id"
                name="formHorizontalRadios"
                id="formHorizontalRadios2"
                value="servicerAccountId"
                checked={this.state.selectedOption === 'servicerAccountId'}
                onChange={this.handleRadioChange}
              />
            </Col>
          </Form.Group>
        </Form>
        {appointmentWorking && (
          <Row className="mt-1 ps-3 pe-3">
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text"></span>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
