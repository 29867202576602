/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import axios from 'axios'

class Panel extends Component {
  componentDidMount = async () => {
    let openSearchUser = process.env.REACT_APP_OPENSEARCH_USER
    let openSearchURL = process.env.REACT_APP_OPENSEARCH_URL
    let openSearchKey = process.env.REACT_APP_OPENSEARCH_KEY

    let username = 'kustomer'
    let password = 'TA3#2r88k52u'
    let basicAuth = 'Basic ' + btoa(username + ':' + password)

    const contractDetail = async () => {
      let details = await axios('https://es.opwforge.com/pcrs_glue/_search', {
        auth: {
          username: 'kustomer',
          password: 'TA3#2r88k52u',
        },
        method: 'GET',
        data: {
          query: {
            term: {
              'Contract Number.keyword': 'ON11397115',
            },
          },
        },
      })
      console.log('Opensearch: ', details)
    }
  }
  render() {
    return <React.Fragment></React.Fragment>
  }
}

export default Panel
