import React from 'react'
import { Form } from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

export const StreetInput = ({ address, isAddressValidated, isAddressValid, handleInputChange }) => {
  const onSelect = (_address, placeId, suggestion) => {
    geocodeByPlaceId(placeId)
      .then((results) => {
        if (results && results.length > 0) {
          const components = results[0].address_components
          if (components && components.length > 0) {
            const postalCode = components.find((comp) => comp.types.includes('postal_code'))
            const city = components.find(
              (comp) => comp.types.includes('locality') || comp.types.includes('sublocality'),
            )
            const country = components.find((comp) => comp.types.includes('country'))
            const state = components.find((comp) =>
              comp.types.includes('administrative_area_level_1'),
            )

            handleInputChange({
              target: {
                id: 'full-address',
                value: {
                  country: country.short_name,
                  postalCode: postalCode.long_name,
                  city: city.long_name,
                  state: state.short_name,
                },
              },
            })
          }
        }
      })
      .catch((error) => console.error(error))
    handleInputChange({
      target: {
        id: 'address',
        value: suggestion.formattedSuggestion.mainText,
      },
    })
  }

  const handleChange = (value) => {
    handleInputChange({
      target: {
        id: 'address',
        value: value,
      },
    })
  }

  const searchOptions = {
    types: ['street_number', 'route'],
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={onSelect}
      searchOptions={searchOptions}
      className='location-search'
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Form.Control
            autoComplete='off'
            className={!isAddressValidated ? null : isAddressValid ? 'is-valid' : 'is-invalid'}
            onChange={(event) => {
              handleInputChange(event)
            }}
            placeholder='Street Address'
            size='sm'
            type='text'
            value={address}
            {...getInputProps()}
            aria-describedby='address-popover'
          />
          {(suggestions.length > 0 || loading) && (
            <div className='suggestions-dropdown-container'>
              <ListGroup className='suggestions-dropwdown'>
                {loading && (
                  <ListGroup.Item className='list-item-text' variant='light'>
                    Loading...
                  </ListGroup.Item>
                )}
                {suggestions.map((suggestion, index) => (
                  <ListGroup.Item
                    className='list-item-text'
                    key={index}
                    action
                    variant='light'
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
          <Form.Control.Feedback type='invalid'>Please provide an address.</Form.Control.Feedback>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
