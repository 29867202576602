import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Delivery from '../../img/delivery-box (1) 1.svg'

export default function RescheduleDialog({
  open,
  rescheduleReasonList,
  handleClose,
  value,
  handleChange,
  openRescheduleAppointmentDialog,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      PaperProps={{
        style: {
          borderRadius: '20px',
          width: '29rem',
          height: '30rem',
        },
      }}
    >
      <DialogTitle className='text-center' sx={{ m: 2, p: 2, mb: 0 }} id='customized-dialog-title'>
        <img
          src={Delivery}
          alt='user-pic'
          height='45px'
          width='45px'
          className='rounded-circle p-2'
          style={{ background: 'rgba(235, 248, 255, 1)' }}
        />
      </DialogTitle>

      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 15,
          top: 15,
          height: '25px',
          width: '25px',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent className='text-center'>
        <Grid>
          <span className='fs-5 fw-semibold'>Do you want to Reschedule the Appointment?</span>
        </Grid>
        <br />
        <Grid>
          <span className='fw-normal fs-6'>Please provide the reason to reschedule:</span>
          <br />
          <br />
          <Select
            className='w-75'
            value={value}
            onChange={(event) => {
              handleChange(event.target.value)
            }}
          >
            {rescheduleReasonList?.map((item) => (
              <MenuItem key={item.reasonCodeMasterId} value={item.reasonName}>
                {item.reasonName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <br />
        <Grid item xs={12} className='d-flex justify-content-center mt-4'>
          <Button variant='outlined' onClick={handleClose}>
            No
          </Button>
          <Button
            variant='contained'
            disabled={!value ? true : false}
            className='ms-3'
            onClick={openRescheduleAppointmentDialog}
          >
            Yes
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
